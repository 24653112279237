<template>
  <div class="borrowTabsPage" id="borrowTabsPage">
    <tabs1 v-model="activeTab" :options="tabList" @change="tabChange"></tabs1>
    <div class="page-container"><router-view /></div>
  </div>
</template>

<script>
let bgImg = require("../../../../static/iconImage/operationCenter.png");

export default {
  name: "borrowTabsPage",
  components: {},
  watch: {
    loading: {
      handler(value) {
        if (value) {
          this.startLoading("workTabsPage");
        } else {
          if (this.loadingView == null) {
            return;
          }
          this.loadingView.close();
        }
      },
    },
  },
  data() {
    return {
      loading: false,
      loadingView: null,
      bgImg: bgImg,
      query: {},
      activeTab: "/deviceBorrow/borrowTabsPage/allDeviceList",
      tabList: [
        {
          title: "全部",
          path: "/deviceBorrow/borrowTabsPage/allDeviceList",
        },
        {
          title: "我的预约",
          path: "/deviceBorrow/borrowTabsPage/mindReservation",
        },
        {
          title: "扫码借用",
          path: "/deviceBorrow/borrowTabsPage/deviceScanningList",
        },
        {
          title: "归还",
          path: "/deviceBorrow/borrowTabsPage/giveBackList",
        },
        {
          title: "保养消毒",
          path: "/deviceBorrow/borrowTabsPage/disinfectList",
        },
        {
          title: "报障",
          path: "/deviceBorrow/borrowTabsPage/deviceFailureList",
        },
      ],
    };
  },
  created() {
    this.activeTab = this.$route.path;
  },
  mounted() {
    this.$bus.on("clickOperationCenterItem", this.clickOperationCenterItem);
    let userInfo = this.$store.getters.userInfo;
    let tenantId = userInfo.tenantId;
    let timeType = 1;
    let timeStr = this.formatDayDate(new Date().getTime());
    let startTime = timeStr + " 00:00:00";
    let endTime = timeStr + " 23:59:59";
    this.query["timeType"] = timeType;
    this.query["startTime"] = startTime;
    this.query["endTime"] = endTime;
    this.query["tenantIds"] = tenantId;
  },
  beforeDestroy() {
    this.$bus.off("clickOperationCenterItem", this.clickOperationCenterItem);
  },
  methods: {
    clickOperationCenterItem: function (item) {
      this.activeName = item.path;
    },
    tabChange(e) {
      this.activeTab = e;
      this.$router.replace(e).catch(e=>{});
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.borrowTabsPage {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    360deg,
    rgba(114, 171, 253, 0) 0%,
    #568efc 70%,
    #3e73fb 100%
  );
  background-size: 100% 195px;
  background-repeat: no-repeat;
  .page-container {
    height: calc(100% - 32px);
  }
}
</style> 